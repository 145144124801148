<template>
  <div>
    <v-app-bar fixed color="headerColor" elevate-on-scroll>
      <v-toolbar-title class="onHeaderColor--text d-flex align-center">
        <div style="width: 140px; height: 60px">
          <img class="py-1" :src="logo" style="width: 100%; height: 100%" />
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <div class="d-flex align-center">
      <v-badge bordered color="primary" content="6" offset-y="13"></v-badge>
        <img src="@/assets/icon/activeNotif.svg" width="26" height="27">
      </div> -->
      <div class="d-flex align-center" @click="openSearch()">
        <img width="19" class="ml-2" src="@/assets/icon/search.svg" />
      </div>
      <!-- <div @click="opneInbox()" class="d-flex align-center">
        <img src="@/assets/icon/notif.svg" width="26" height="27">
      </div> -->
    </v-app-bar>
  </div>
</template>
<style lang="scss" scoped>
.v-badge__wrapper {
  .v-badge__badge {
    min-width: 10px;
    height: 15px;
    padding: 1px 0 0 0;
  }
}
</style>
<script>
import router from "@/router";
import { create_url_image } from "@/models/changeType";
export default {
  data: () => ({
    logo: "",
  }),
  mounted() {
    const config = localStorage.getItem("logo_header");
    if (config) {
      this.logo = create_url_image(config, 140, 60);
    }
  },
  methods: {
    opneInbox() {
      router.push("/support");
    },
    searchinput() {
      setTimeout(() => {
        router.push("/search_result");
      }, 1000);
    },
    openSearch() {
      router.push("/search");
    },
  },
};
</script>